import { getImageFraction, getVideoFraction } from '@/helpers/getMediaFraction';
import {
    IMAGE_ASSET_TYPE,
    VIDEO_ASSET_TYPE,
    ASSET_STYLE_SQUARE,
    ASSET_STYLE_LANDSCAPE,
    ASSET_STYLE_PORTRAIT,
    ASSET_STYLE_2_BY_3,
    ASSET_STYLE_IMAGE,
    ASSET_STYLE_VIDEO,
} from '@/components/ad-studio/store/constants';

export const getAssetStyles = async (mediaUrl, assetType) => {
    const assetStyles = [];
    const fraction = assetType.toLowerCase() === IMAGE_ASSET_TYPE ? await getImageFraction(mediaUrl) : await getVideoFraction(mediaUrl);

    // SQUARE
    if (fraction == 1) assetStyles.push(ASSET_STYLE_SQUARE);

    // 2 BY 3
    if (fraction < 1 && fraction >= 0.666) assetStyles.push(ASSET_STYLE_2_BY_3);

    // LANDSCAPE
    if (fraction > 1) assetStyles.push(ASSET_STYLE_LANDSCAPE);

    // PORTRAIT
    if (fraction < 1) assetStyles.push(ASSET_STYLE_PORTRAIT);

    // IMAGE
    if (assetType.toLowerCase() === IMAGE_ASSET_TYPE) assetStyles.push(ASSET_STYLE_IMAGE);

    // VIDEO
    if (assetType.toLowerCase() === VIDEO_ASSET_TYPE) assetStyles.push(ASSET_STYLE_VIDEO);

    return assetStyles;
}