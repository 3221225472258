export const removeHttp = (url) => {
    try {
        const { hostname } = new URL(url);
        return hostname ? hostname.replace('www.', '').replace(/\/$/, '').toUpperCase() : '';
    } catch(error) {
        if(error instanceof TypeError) {
            if (url){
                return url.split('?')[0];
            } else {
                return url;
            }
        }
    }
};
