export const getImageFraction = async (mediaUrl) => {
    const imageFraction = () => new Promise(resolve => {
        const img = new Image();
        img.src = mediaUrl;
        img.onload = function() {
            resolve(img.width / img.height);
        };
    });
    return await imageFraction();
}

export const getVideoFraction = async (mediaUrl) => {
    const videoFraction = () => new Promise(resolve => {
        const video = document.createElement('video');
        video.src = mediaUrl;
        video.onloadedmetadata = () => {
            resolve(video.videoWidth / video.videoHeight);
        };
    });
    return await videoFraction();
}